<template>
  <div class="bgColor">
    <div class="title">我们的优势</div>
    <div class="bg content" id="demo">

      <div class="feature">

        <div class="demoTitle">
          <div class="_tilte">运维简单、配置灵活
            部署迅速、专线专用
          </div>

        </div>
      </div>
      <div class="feature">

        <div class="demoTitle">
          <div class="_tilte">跨境MPLS
            专线带宽充裕，线路优质
          </div>

        </div>
      </div>
      <div class="feature">

        <div class="demoTitle">
          <div class="_tilte">安全可靠，原生IP独享，
            最大程度减免限流、封号等风险
          </div>

        </div>
      </div>
      <div class="feature">

        <div class="demoTitle">
          <div class="_tilte">合法合规、线路零
            风险，企业级解决方案
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "web-demo",
  data() {
    return {}
  },
  mounted() {

  },
  methods: {},
}
</script>

<style scoped lang="less">
.bgColor {
  opacity: 1;
  background-image: linear-gradient(180deg, #ff8f1fad -84.8%, #ff8f1f21 184.8%);
}

.title {
  line-height: 200px;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  color: white;
}

.red {
  color: #ff8f1feb;
}


.isNotPhone {
  display: none;
}

.bg {
  display: flex;

  .feature {
    flex: 1;
  }


  .demoTitle {
    text-align: center;

    ._tilte {
      color: #111111;
      font-size: 23px;
      font-weight: 700;
      max-width: 250px;
      color: white;
      margin: 30px auto 100px auto;

    }

  }
}


@media (max-width: 992px) {


  .imgContent {
    max-width: 260px;
    margin: auto;
    height: auto !important;
    padding: 0px !important;
    margin-top: 80px !important;

    img {
      margin: auto;
      position: relative;
      left: 10px;
    }
  }

  .bg {
    display: inline-block;
  }

  .showtext {
    max-width: 400px;
    margin: auto;
    font-size: 20px;
    padding: 40px;
  }


  .Carousel {
    max-height: 100% !important;
    width: 100% !important;
    margin: auto;
    transform: scale(1.5);
  }

  .describes {
    width: 100% !important;
    text-align: center;
    margin-top: 70px !important;

    .smallTextColor {
      margin-top: 30px;
    }
  }

  .isPhone {
    display: none;
  }

  .isNotPhone {
    display: block;
  }
}

</style>
