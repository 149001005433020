<template>
  <div class="bgColor">
    <div class="bg" id="demo">
      <div class="content">
        <div class="_content">
          <div class="demoImage">
            <img src="./../../src/assets/tikeAssets/person_add_alt_1.png" alt="">
          </div>
          <div class="demoTitle">
            <div class="_tilte">精准达人触达</div>
            <div class="_dec">
              覆盖TikTok达人<span class="red">1.8亿+500万+</span>
              带货达人 自动
              帮你解决搜索达人建联难题
            </div>
          </div>
        </div>
        <div class="_content">
          <div class="demoImage">
            <img src="./../../src/assets/tikeAssets/flight_takeoff.png" alt="">
          </div>
          <div class="demoTitle">
            <div class="_tilte">提高运营效率</div>
            <div class="_dec">
              <span class="red">1小时</span> 触达 <span class="red">500+</span> 达人
              <span class="red">全自动</span> 流程工作
              告别传统繁琐手段
            </div>
          </div>
        </div>
        <div class="_content">
          <div class="demoImage">
            <img src="./../../src/assets/tikeAssets/title_2.png" alt="">
          </div>
          <div class="demoTitle">
            <div class="_tilte">AI助力无障碍联系
            </div>
            <div class="_dec">
              强大 <span class="red">AI引擎</span> 自动筛选与品牌契合达人 <span class="red">自动</span> 与达人进行 <span
                class="red">本土化专业交流</span> 与海外达人交流成本 <span class="red">最低化</span>
            </div>
          </div>
        </div>


      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "web-demo",
  data() {
    return {}
  },
  mounted() {

  },
  methods: {},
}
</script>

<style scoped lang="less">
.bgColor {
  opacity: 1;
  margin-bottom: 100px;
}

.content {
  display: flex;

  ._content {
    flex: 1;
  }
}

.red {
  color: #ff8f1feb;
}


.isNotPhone {
  display: none;
}

.bg {
  .demoImage {
    margin-top: 100px;
    position: relative;
    height: 150px;

    img {
      width: 150px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .demoTitle {
    text-align: center;

    ._tilte {
      color: #111111;
      font-size: 23px;
      font-weight: 700;
    }

    ._dec {
      max-width: 250px;
      margin: 20px auto 0px auto;
    }
  }
}


@media (max-width: 992px) {

  .content {
    display: inline-block !important;
    margin-left: 50%;
    transform: translateX(-50%);

    ._content {
    }
  }

  .imgContent {
    max-width: 260px;
    margin: auto;
    height: auto !important;
    padding: 0px !important;
    margin-top: 80px !important;

    img {
      margin: auto;
      position: relative;
      left: 10px;
    }
  }


  .showtext {
    max-width: 400px;
    margin: auto;
    font-size: 20px;
    padding: 40px;
  }


  .Carousel {
    max-height: 100% !important;
    width: 100% !important;
    margin: auto;
    transform: scale(1.5);
  }

  .describes {
    width: 100% !important;
    text-align: center;
    margin-top: 70px !important;

    .smallTextColor {
      margin-top: 30px;
    }
  }

  .isPhone {
    display: none;
  }

  .isNotPhone {
    display: block;
  }
}

</style>
