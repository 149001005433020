<template>
  <div class="bg" id="pricing">
    <div class="content fadeIn">
      <div class="title">
        <span>TikTok达人带货新模式助你大卖</span>
      </div>
      <div class="video">
        <iframe src="//player.bilibili.com/player.html?aid=1501013525&bvid=BV11S421N7Ev&cid=1451739669&p=1"
                scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"
                width="100%" height="100%"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import {submitUserEmail} from "@/api/index"
import {ElMessage} from 'element-plus'

export default {
  name: "Preimum Price",
  data() {
    return {}
  },

  methods: {},
}
</script>

<style scoped lang="less">
.bg {
  height: 800px;
}

.title {
  font-size: 35px;
  line-height: 62px;
  font-weight: 700;
  color: #ff8f1f;
  text-align: center;
  margin: 20px 0px 20px 0px;
}

.video {
  width: 100%;
  height: 600px
}
</style>
