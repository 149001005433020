<template>
  <div class="bgColor">
    <div class="bg" id="intro">
      <div class="content">
        <div class="titleImage">
          <img src="./../../src/assets/tikeAssets/title_4.png" alt="">
        </div>
        <div class="title_text">
          与全球各国大型电信商合作，用户独享一手住宅IP
        </div>
        <div class="featrue ">
          <div class="featureContent animateLeft">
            <img src="./../../src/assets/tikeAssets/Header_1.png" alt="">
            <div class="title_1">
              适合TikTok Shop视频养号
            </div>
            <div class="title_feature">
              <div class="_feature">
                全球大型合作电信商，专业住宅IP，不封号不限流
              </div>
              <div class="_feature">独享固定IP，不与他人共享节点，最低化操作风险</div>
              <div class="_feature">安全稳定不卡顿</div>
              <div class="_feature">支持紫鸟浏览器、Mac、WIndows等连接方式</div>
            </div>
            <div class="nowUse">
              <a href="https://tike888.com/admin/#/login" target="_blank">立即使用</a>
            </div>

          </div>
          <div class="featureContent">
            <img src="./../../src/assets/tikeAssets/Header_2.png" alt="">
            <div class="title_1">
              适合TikTok直播，跨境直播
            </div>
            <div class="title_feature">
              <div class="_feature">
                全球大型合作电信商，专业住宅IP，不封号不限流
              </div>
              <div class="_feature">独享固定IP，不与他人共享节点，最低化操作风险</div>
              <div class="_feature">低延迟、高速度、不卡顿、不丢包</div>
              <div class="_feature">直播专线视频加速技术</div>
              <div class="_feature">支持Mac、Windows、手机官方直播端</div>
            </div>
            <div class="nowUse" style="margin-top: 30px;margin-bottom: 20px">
              <a href="https://tike888.com/admin/#/login" target="_blank">立即使用</a>
            </div>

          </div>
          <div class="featureContent animateRight">
            <img src="./../../src/assets/tikeAssets/Header_3.png" alt="">
            <div class="title_1">
              适合TikTok Shop运营
            </div>
            <div class="title_feature">
              <div class="_feature">
                全球大型合作电信商，专业住宅IP，不封号不限流
              </div>
              <div class="_feature">独享固定IP，不与他人共享节点，最低化操作风险</div>
              <div class="_feature">安全稳定不卡顿</div>
              <div class="_feature">支持紫鸟浏览器、Mac、WIndows等连接方式</div>
            </div>
            <div class="nowUse">
              <a href="https://tike888.com/admin/#/login" target="_blank">立即使用</a>
            </div>

          </div>

        </div>

      </div>

    </div>
  </div>
  <img src="./../../src/assets/tikeAssets/17088520677793327482.png" alt="" style="position: absolute;
    top: -370px;
    left: -629px;">
  <img src="./../../src/assets/tikeAssets/map.png" alt=""
       style="position: absolute;left: 50%;transform: translateX(-50%);top: 150px;width: 100%">
</template>

<script>
export default {
  name: "web-intro",
  data() {
    return {}
  },

  methods: {},
}
</script>

<style scoped lang="less">
.bgColor {
  overflow: hidden;
  background-image: linear-gradient(180deg, #ee9c2d -59%, #efa22d 159%);
  position: relative;
  margin-top: 90px;
}

.bg {
  z-index: 9;
}

.title_text {
  color: #ffffff;
  font-size: 42px;
  font-weight: 700;
  line-height: 46px;
  margin-top: 20px;
  text-align: center;
}

.titleImage {
  margin-top: 60px;

  img {
    width: 100%;

  }
}

.featrue {
  display: flex;
}

.featureContent {
  padding-bottom: 28px;
  overflow: hidden;
  border-radius: 15px;
  background-color: #ffffff;
  max-width: 419px;
  border-left: solid 1px #dfdfdf;
  border-right: solid 1px #dfdfdf;
  border-top: solid 1px #dfdfdf;
  border-bottom: solid 1px #dfdfdf;
  margin: 30px 20px;
  flex: 1;

  .title_1 {

    color: #212529;
    line-height: 50px;
    text-align: center;
    font-size: 18px;
    border-bottom: 1px solid #ccc;
  }

  ._feature {
    font-size: 14px;
    line-height: 24px;
    color: #9e9e9e;
    text-align: center;
    margin: 20px auto;
    max-width: 200px;
  }

  .nowUse {
    text-align: center;
    margin-top: 100px;

    a {
      background-color: #ff8f1f;
      padding: 10px 25px;
      color: white;
      text-decoration: none;
      border-radius: 10px;
    }
  }

  img {
    width: 100%;
  }

}

@media (max-width: 992px) {
  .bgColor {
    margin-top: 70px;
  }

  .content {
    display: inline-block !important;
  }

  .featrue {
    display: inline-block !important;
  }

  .bg {
    margin-top: 70px;
    height: auto !important;

  }

  .content {
    width: 100%;
    text-align: center;
    overflow: hidden;
  }

  .title_text {
    font-size: 18px;
  }

  .titleImage {
    width: 100%;
  }


}

</style>
