<template>
  <div class="bgColor">
    <div class="bg" id="intro">


      <div class="title_text">
        我们的电信合作商
      </div>
      <div class="allFeature">
        <div class="feature">
          <div class="demoImage">
            <img src="./../../src/assets/tikeAssets/title4_1.png" alt="">
          </div>
          <div class="demoTitle">
            <div class="_tilte">英国沃达丰电信</div>

          </div>
        </div>
        <div class="feature">
          <div class="demoImage">
            <img src="./../../src/assets/tikeAssets/title4_2.png" alt="" style="top:8px">
          </div>
          <div class="demoTitle">
            <div class="_tilte">美国威讯无线</div>

          </div>
        </div>
        <div class="feature">
          <div class="demoImage">
            <img src="./../../src/assets/tikeAssets/title4_3.png" alt="">
          </div>
          <div class="demoTitle">
            <div class="_tilte">加拿大贝尔</div>

          </div>
        </div>
        <div class="feature">
          <div class="demoImage">
            <img src="./../../src/assets/tikeAssets/title4_4.png" alt="" style="top:18px">
          </div>
          <div class="demoTitle">
            <div class="_tilte">日本KDDI</div>

          </div>
        </div>
        <div class="feature">
          <div class="demoImage">
            <img src="./../../src/assets/tikeAssets/title4_5.png" alt="" style="top: -10px">
          </div>
          <div class="demoTitle">
            <div class="_tilte">韩国SK电讯</div>

          </div>
        </div>
      </div>
      <img class="map_fill" src="./../../src/assets/tikeAssets/map_fill.png" alt=""
           style="">

    </div>
  </div>


</template>

<script>
export default {
  name: "web-intro",
  data() {
    return {}
  },

  methods: {},
}
</script>

<style scoped lang="less">
.bgColor {
  overflow: hidden;
  background: #ff8f1f2e;
  position: relative;
}

.bg {
  z-index: 9;
}

.allFeature {
  display: flex;

  .feature {
    flex: 1;
  }

  .demoImage {
    margin-top: 100px;
    position: relative;
    height: 150px;

    img {
      width: 150px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .demoTitle {
    text-align: center;

    ._tilte {
      color: #111111;
      font-size: 23px;
    }

  }
}

.title_text {
  font-size: 42px;
  font-weight: 700;
  line-height: 100px;
  margin-top: 20px;
  color: #ff8f1f;
  text-align: center;
}

.map_fill {
  margin: 50px 0px;
}

@media (max-width: 992px) {
  .content {
    display: inline-block !important;
  }


  .bg {
    margin-top: 70px;
    height: auto !important;
  }

  .allFeature {
    display: inline-block;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-bottom: 50px;
  }

  .content {
    width: 100%;
    text-align: center;
  }

  .map_fill {
    display: none;
  }
}

</style>
