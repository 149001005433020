<template>
  <div class="bgColor">
    <div class="bg">
      <div class="content">
        <div class="_content">
          <div class="child">
            <div class="allIcon">
              <img src="./../../src/assets/tikeAssets/douyin.png" alt="" class="douyin">
              <img src="./../../src/assets/tikeAssets/taiyue.png" alt="" class="taiyue">
            </div>
          </div>
          <div class="child">
            <div class="title">我们的服务
            </div>
            <div class="title_a"><a href="https://tike888.com/admin/#/login" target="_blank">提客-AI达人邀约工具</a></div>
            <div class="title_a"><a href="https://tike888.com/admin/#/login" target="_blank">住宅IP-独享稳定安全电商节点</a></div>
            <div class="title_a"><a href="https://tike888.com/admin/#/login" target="_blank">电商学习- 你的入门专属导师</a></div>
          </div>
          <div class="child">
            <div class="title">TikTok 官方网站</div>
            <div class="title_a"><a href="https://creatormarketplace.tiktok.com/login" target="_blank">TikTok达人中心
            </a></div>
            <div class="title_a"><a href="https://seller-us.tiktok.com/homepage?shop_region=US" target="_blank">TikTok
              Shop 官方网站</a></div>
          </div>
          <div class="child">
            <div class="codeImage">
              <img src="./../../src/assets/tikeAssets/code.png" alt="">
            </div>
            <div class="banT">商务合作-免费试用</div>

          </div>


        </div>
      </div>
      <div class="ban">Copyright © 钛月科技（广州）有限责任公司,All Rights Reserved</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "web-footer",
  data() {
    return {}
  },
  methods: {
    trun(src) {
      window.open(src, '_blank');
    }
  }
}
</script>

<style scoped lang="less">
.bgColor {
  background: #ff8f1feb;
}

._content {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;

  .child {
    flex: 1;
  }
}

.allIcon {
  position: relative;

  .douyin {
    width: 150px;
  }

  .taiyue {
    width: 500px;
    position: absolute;
    top: -32px;
    left: -172px;
  }
}

.title {
  color: #343a40;
  font-size: 22px;
  font-weight: 700;
}

.title_a a {
  text-decoration: none;
  font-size: 14px;
  line-height: 40px;
  color: #343a40;

}

.codeImage img {
  width: 170px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.banT {
  text-align: center;
  line-height: 80px;
}

.ban {
  text-align: center;
  color: #343a40;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 30px;
}

@media (max-width: 992px) {
  .footerTitle {
    font-size: 38px !important;
  }

  .title_a {
    display: none;
  }

  ._content {
    display: inline-block;
    width: 100%;
  }

  .allIcon {
    display: none;
  }

  .title {
    display: none;
  }

  .child {
    margin-left: 50%;
    transform: translateX(-50%);
  }
}
</style>
