<template>
  <div class="bgColor">
    <div class="bg content" id="demo">

      <div class="feature">
        <div class="demoImage">
          <img src="./../../src/assets/tikeAssets/world.png" alt="">
        </div>
        <div class="demoTitle">
          <div class="_tilte">全球多节点</div>
          <div class="_dec">
            专业各地跨境线路
            专线带宽充裕
            线路优质
          </div>
        </div>
      </div>
      <div class="feature">
        <div class="demoImage">
          <img src="./../../src/assets/tikeAssets/lock.png" alt="">
        </div>
        <div class="demoTitle">
          <div class="_tilte">纯净住宅IP</div>
          <div class="_dec">
            与全球大型电信商合作
            独享原生住宅IP
            不限流、不封号
          </div>
        </div>
      </div>
      <div class="feature">
        <div class="demoImage">
          <img src="./../../src/assets/tikeAssets/headset.png" alt="">
        </div>
        <div class="demoTitle">
          <div class="_tilte">7*24客服支持
          </div>
          <div class="_dec">
            全天候专业团队
            在线解决问题
            陪伴你一起大卖
          </div>
        </div>
      </div>
      <div class="feature">
        <div class="demoImage">
          <img src="./../../src/assets/tikeAssets/send.png" alt="">
        </div>
        <div class="demoTitle">
          <div class="_tilte">高速稳定
          </div>
          <div class="_dec">
            高速、低延迟
            不卡顿
            顺畅网络助你海外大卖
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "web-demo",
  data() {
    return {}
  },
  mounted() {

  },
  methods: {},
}
</script>

<style scoped lang="less">
.bgColor {
  opacity: 1;
  margin-bottom: 100px;
}

.red {
  color: #ff8f1feb;
}


.isNotPhone {
  display: none;
}

.bg {
  display: flex;

  .feature {
    flex: 1;
  }

  .demoImage {
    margin-top: 100px;
    position: relative;
    height: 150px;

    img {
      width: 150px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .demoTitle {
    text-align: center;

    ._tilte {
      color: #111111;
      font-size: 23px;
      font-weight: 700;
    }

    ._dec {
      max-width: 250px;
      margin: 20px auto 0px auto;
    }
  }
}


@media (max-width: 992px) {
  .bg {
    display: inline-block !important;
  }

  .imgContent {
    max-width: 260px;
    margin: auto;
    height: auto !important;
    padding: 0px !important;
    margin-top: 80px !important;

    img {
      margin: auto;
      position: relative;
      left: 10px;
    }
  }

  .showtext {
    max-width: 400px;
    margin: auto;
    font-size: 20px;
    padding: 40px;
  }


  .Carousel {
    max-height: 100% !important;
    width: 100% !important;
    margin: auto;
    transform: scale(1.5);
  }

  .describes {
    width: 100% !important;
    text-align: center;
    margin-top: 70px !important;

    .smallTextColor {
      margin-top: 30px;
    }
  }

  .isPhone {
    display: none;
  }

  .isNotPhone {
    display: block;
  }
}

</style>
