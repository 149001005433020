<template>
  <div class="bgColor">
    <div class="bg" id="intro">
      <div class="content">

        <div class="introContent animateLeft">
          <div class="introTitle">
            <img src="./../../src/assets/tikeAssets/title_1.png" alt="" class="title_1">
            <div class="title_1_text">· 营销新模式助你海外大卖</div>
          </div>

          <div class="btn_turn">
            <a href="https://tike888.com/admin/#/login" target="_blank">注册</a>
          </div>
        </div>

        <div class="imgContent animateRight">
          <div class="register">
            <div class="register_title">欢迎注册</div>
            <div class="register_phone">
              <div>手机号注册 <a href="https://tike888.com/admin/#/login" target="_blank" class="turn_a"
                            style="font-weight: 500;color: rgba(1, 108, 188, 1)">切换到邮箱注册</a></div>
              <div>
                <el-input v-model="phone" placeholder="请输入手机号" style="height: 40px"/>
              </div>
            </div>
            <div class="register_phone_code">
              <div>输入验证码</div>
              <div style="position: relative">
                <el-input
                    v-model="code"
                    placeholder="请输入验证码"
                    style="height: 40px"
                >
                  <template #append>
                    <div class="sendCode" @click="sendCode">{{ text }}</div>
                  </template>
                </el-input>
              </div>
            </div>
            <div class="forget"><a href="https://tike888.com/admin/#/login" target="_blank" class="turn_a">忘记密码</a>
            </div>
            <div class="register_btn">
              <el-button round style="width: 100%;height: 40px;color: white;line-height: 40px" :color="'#efa22d' "
                         @click="register">
                立即注册
              </el-button>
            </div>
            <div class="register_btn_text">
              登录即同意 <a href="https://tike888.com/admin/agreement.html" target="_blank" class="turn_a">用户协议</a>和<a
                href="https://tike888.com/admin/privacy.html" target="_blank"
                class="turn_a">隐私政策</a>
            </div>
            <div>已有账号？<a href="https://tike888.com/admin/#/login" target="_blank" class="turn_a" style="color: #ee9c2d">点击登录</a>
            </div>
          </div>
        </div>

      </div>
      <div class="title_2 content ">

        <div class="_title_2 animateLeft">
          <div class="_title2_t">-10小时</div>
          <div class="_title2_d">的工作时间</div>
        </div>
        <div class="_title_2">
          <div class="_title2_t">30%+</div>
          <div class="_title2_d">达人回复率</div>
        </div>
        <div class="_title_2 animateRight">
          <div class="_title2_t">$3000+</div>
          <div class="_title2_d">日均提高营业额</div>
        </div>

      </div>
    </div>
  </div>
  <img src="./../../src/assets/tikeAssets/17088520677793327482.png" alt="" style="position: absolute;
    top: -370px;
    left: -629px;">
  <img src="./../../src/assets/tikeAssets/map.png" alt=""
       style="position: absolute;left: 50%;transform: translateX(-50%);top: 150px;width: 100%">
</template>

<script>
import axios from "axios";
import {ElMessage} from "element-plus";

export default {
  name: "web-intro",
  data() {
    return {
      phone: '',
      code: '',
      text: "发送验证码",
      agin: true
    }
  },

  methods: {
    sendCode() {
      let url = 'https://server.aivocado.online/tt-tool-server/user/retrieve-validation-code'
      let data = {
        email: '',
        phone: this.phone
      }
      if (!this.agin) {
        return;
      }
      this.registerText(60)
      this.agin = false

      axios.post(url, data).then(res => {
        console.log(res)
        if (res.data.code == 0) {
          ElMessage({
            message: '发送成功',
            type: 'success',
          })
        } else {
          ElMessage({
            message: '发送失败稍后重试',
            type: 'warning',
          })
        }
      })
    },
    registerText(m) {
      this.text = `${m}秒后重试`
      if (m <= 0) {
        this.text = "发送验证码"
        this.agin = true
        return
      }
      setTimeout(() => {
        this.registerText(m - 1)
      }, 1000)
    },
    register() {
      let url = 'https://server.aivocado.online/tt-tool-server/user/register'
      let data = {
        "email": "",
        "phone": this.phone,
        "password": "tike" + new Date().getTime(),
        "validation_code": this.code,
        "invitation_code": ""
      }
      axios.post(url, data).then(res => {
        console.log(res)
        if (res.data.code == 0) {
          ElMessage({
            message: '注册成功，需要再当前页面重新登录',
            type: 'success',
          })
          window.location.href = 'https://tike888.com/admin/#/login';

        } else {
          ElMessage({
            message: '注册失败稍后重试',
            type: 'warning',
          })
        }
      })
    }
  },
}
</script>

<style scoped lang="less">
.bgColor {
  overflow: hidden;
  background-image: linear-gradient(180deg, #ee9c2d -59%, #efa22d 159%);
  position: relative;
  margin-top: 70px;

}

.register {
  background: rgba(250, 239, 215, 1);
  margin-top: 80px;
  padding: 40px 40px;

  .register_title {
    color: rgba(255, 143, 31, 1);
    text-align: center;
    font-size: 30px;
    line-height: 70px;
  }

  .register_phone {
    color: rgba(102, 102, 102, 1);
    line-height: 40px;


    input {
      width: 100%;
      padding: 0px 10px;
      height: 40px;
      border-radius: 5px;
      border: 1px solid rgba(102, 102, 102, 0.35);
      outline: none;
      font-size: 24px;
    }
  }

  .register_phone_code {
    color: rgba(102, 102, 102, 1);
    line-height: 40px;


    input {
      width: 100px;
      padding: 0px 10px;
      height: 50px;
      border-radius: 5px;
      border: 1px solid rgba(102, 102, 102, 0.35);
      outline: none;
      font-size: 24px;

    }

    .sendCode {

      cursor: pointer;

    }
  }

  .forget {
    line-height: 50px;


  }

  .turn_a {
    color: black;
    font-weight: 600;
  }
}

.bg {

  height: 800px;
  z-index: 9;

  .introContent {
    flex: 4;
  }


  .content {
    height: auto;
    margin-top: 0px;
    display: flex;
  }

  .title_2 {
    display: inline-block;
    text-align: center;
    margin-left: 50%;
    margin-top: 70px;
    transform: translateX(-50%);
    color: white;
    display: flex;

    ._title_2 {
      flex: 1;
    }

    ._title2_t {
      font-size: 50px;
      font-weight: 600;
    }

    ._title2_d {
      font-size: 30px;
    }
  }

  .register_btn_text {
    text-align: center;
    line-height: 60px;
  }

  .introTitle {
    height: 190px;
    text-align: left;
    vertical-align: top;
    padding-top: 150px;
    width: 600px;

    span {

      letter-spacing: 1.08px;
      line-height: 70px;
    }
  }

  .btn_turn a {
    border-radius: 25px;
    border: 2px solid rgba(255, 255, 255, 1);
    color: white;
    text-decoration: none;
    line-height: 100px;
    padding: 5px 30px;
  }

  .btn_turn {
    margin-top: 40px;
  }

  .introDescibe {
    width: 400px;
    margin-top: 30px;

    span {

      line-height: 20px;
      text-align: left;
      vertical-align: top;
    }
  }

  .imgContent {
    position: relative;
    width: 100%;
    height: 100%;
    flex: 3;


    img {
      position: absolute;
      top: 50px;
      right: 0px;
      z-index: 2;
      width: 100%;
      max-width: 260px;
      height: auto;
      object-fit: contain;
      max-height: 100%;
    }
  }

  .title_1 {
    height: 130px;
    z-index: 9;
  }

  .title_1_text {
    color: #ffffff;
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    margin-top: 40px;
  }
}

@media (max-width: 992px) {
  .content {
    display: inline-block !important;
  }


  .bg {
    margin-top: 70px;
    height: auto !important;
  }

  .content {
    width: 100%;
    text-align: center;
  }

  .introContent {
    padding: 0px 40px;
    margin-bottom: 30px;
  }

  .introTitle {
    padding-top: 50px !important;
    max-width: 400px;
    margin: auto;
    height: auto !important;
    width: 100% !important;
    text-align: center !important;

    span {
      line-height: 50px !important;
    }
  }

  .introDescibe {
    margin-top: 40px;
    width: 100% !important;
  }

  .title_2 {
    margin-bottom: 50px;
  }

  .imgContent {
    max-width: 100%;
    margin: auto;

    img {
      max-height: 500px !important;
      position: relative !important;
      top: 20px !important;
    }
  }

  .introTitle img {
    width: 100%;
  }

  .title_1 {
    height: auto !important;
  }
}

</style>
