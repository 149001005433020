<template>
  <div class="bg" id="tryItNow">
    <div class="content">
      <div class="menu">
        <div v-for="(item,index) in items" :key="item" class="_menu" @click="turn(index)"
             :class="index==carouselIndex?'active':''">{{
            item.title
          }}
        </div>
      </div>
      <div class="carousel">
        <el-carousel :motion-blur="true" indicator-position="outside" :initial-index="carouselIndex" @change="autoTurn"
                     ref="carousel">
          <el-carousel-item v-for="item in items" :key="item"
          >
            <div class="_content">
              <div class="_image">
                <img :src="item.url" alt="">
              </div>
              <div class="_dec">
                <div class="dec_title">{{ item.title }}</div>
                <div class="dec_dec">{{ item.dec }}</div>
                <div class="btn">
                  <a class="test test_1" href="https://tike888.com/admin/#/login" target="_blank">免费注册</a>
                  <a class="test test_2" href="https://tike888.com/admin/#/login" target="_blank">免费试用</a>
                </div>
              </div>

            </div>

          </el-carousel-item>
        </el-carousel>
      </div>

    </div>
    <img src="./../../src/assets/tikeAssets/17088520677793327482.png" alt="" class="bg_image">
  </div>

</template>

<script>


import {ref} from "vue";

export default {
  name: "web-try",
  data() {
    return {
      items: [
        {
          title: '批量邀约达人',
          dec: 'TikTok达人广场，自定义广场达人邀约和私信计划，自动邀约和私信达人、自动邀约和私信达人。以高效的自动化工具，轻松批量邀约达人，省时省力，让您专注于更战略性的业务方向。',
          url: require('./../../src/assets/tikeAssets/feature1.png')
        },
        {
          title: '邀约自动模版',
          dec: '提供最新爆款邀约文案模版，提高触达成功率。提供多语言翻译，润色私信的功能，用最地道的语言联系达人。',
          url: require('./../../src/assets/tikeAssets/feature2.png')
        },
        {
          title: '导出达人信息',
          dec: '批量导出已联系的TikTok达人，用户为TikTok网红达人分别打上标签，进行分类管理。可导出到Excel便于商家二次邀约开发达人，深度与达人沟通建立长期合作',
          url: require('./../../src/assets/tikeAssets/feature3.png')
        },
        {
          title: '支持多平台',
          dec: '支持从TikTokSHop和TikTok Creator Marketplace向达人发起邀请。无论你是否拥有TikTok商户，都可以无障碍与海外达人建联，大大减少品牌出海成本。',
          url: require('./../../src/assets/tikeAssets/feature4.png')
        },
        {
          title: 'AI与达人沟通',
          dec: '配置强大AI引擎，提供多语言翻译，润色私信的功能，用最地道的语言联系达人。 让你和达人在沟通过程中语言交流不再是难题。',
          url: require('./../../src/assets/tikeAssets/feature5.png')
        },
      ],
      carouselIndex: 0
    }
  },
  methods: {
    turn(index) {
      console.log(index, "-----")
      this.carouselIndex = index
      this.$refs.carousel.setActiveItem(index)
    },
    autoTurn(val, old) {
      console.log(val, "------", old)
      this.carouselIndex = val
    }
  },

}
</script>

<style scoped lang="less">
.bg {
  background: linear-gradient(180deg, rgba(255, 143, 31, 0.13) 0%, rgba(255, 143, 31, 0.68) 100%);
}

.menu {
  display: flex;
}

._menu {
  flex: 1;
  text-align: center;
  padding: 15px 30px;
  font-size: 18px;
  line-height: 18.5px;
  font-weight: 800;
  cursor: pointer;
  background: #f8f9fa;
  margin: 30px 20px 50px 20px;
  border-radius: 5px;
  transition: all 0.5s;
}

.test {
  line-height: 22.5px;
  font-size: 22px;
  line-height: 22px;
  font-weight: 800;
  text-decoration: none;
  padding: 8px 24px;
  border-radius: 20px;
  border: 5px #ff8f1f solid;
}

.bg_image {
  position: absolute;
  top: 0px;
  right: -700px;
  transform: rotate(180deg);
}

.btn {
  margin-top: 30px;
}

.test_1 {
  color: white;
  background-color: #ff8f1f;
}

.test_2 {
  color: #ff8f1f;
  background: white;
  margin-left: 10px;
}

.carousel {
  margin: 0px 0px 70px 0px;
  height: 400px;
}

.active {
  color: white !important;
  background: #ff8f1f !important;
}

._content {
  display: flex;
  height: 400px;

  ._image {
    width: 80px;
    flex: 1;

    img {
      width: 100%;
    }
  }

  ._dec {
    flex: 1;
    padding: 10px 30px;

    .dec_title {
      color: #343a40;
      font-size: 36px;
      font-weight: 800;
      line-height: 50px;
    }

    .dec_dec {
      margin-top: 50px;
      font-size: 20px;
      line-height: 30px;
      color: #6c757d;
    }
  }
}

@media (max-width: 992px) {
  .menu {
    display: none;
  }

  ._content {
    display: inline-block;
    height: 800px
  }

  ._image {
    width: 100% !important;
    margin-top: 40px;
  }

  .carousel {
    height: 700px;
  }

  .bg_image {
    z-index: -1;
  }

  .test {
    font-size: 14px;
    padding: 8px 22px;
  }

}
</style>
