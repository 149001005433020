import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import index from '@/components/index'
import ISP from '@/components/ISPindex'

const routes = [
    {path: "/", component: index},
    {path: "/home", component: index},
    {path: "/ISP", component: ISP},
]
const router = createRouter({
    routes,
    history: createWebHashHistory()
})

export default router
