<template>
  <div class="header" :class="headerActive?'showdown':''">
    <div class="bg">
      <div class="content">
        <el-row>
          <el-col :span="4">
            <div class="logo">
              <img src="../../src/assets/tikeAssets/homeLogo.png" alt="">
            </div>
          </el-col>
          <el-col :span="10" class="PC">
            <div class="menu">
              <ul>
                <li v-for="(item,i) in menu" :key="i">
                  <a :href="item.url" class="turn" :target="i==2?'_blank':''">{{ item.title }}</a>
                </li>
              </ul>
            </div>
          </el-col>
          <el-col :span="6" class="PC">
            <div class="login" v-if="!isphone">
              <a href="https://tike888.com/admin/#/login" class="login_button active">登录/注册</a>
            </div>

          </el-col>
          <el-col :span="4" class="PC">
            <div class="login" v-if="!isphone">
              <a href="https://tike888.com/admin/#/login" class="login_button active">控制台</a>
            </div>

          </el-col>
          <el-col :span="16" class="phone">

          </el-col>
          <el-col :span="4" class="phone">
            <div class="_menu">

              <el-button color="#ff8f1f" @click="showMenu==true?showMenu=false:showMenu=true">
                <el-icon style="color: white">
                  <Menu/>
                </el-icon>
              </el-button>

            </div>
          </el-col>
        </el-row>
      </div>
      <div class="_menuDiv" :style="{height:showMenu?'130px':'0px'}">
        <div class="menuDiv" v-for="(item,i) in menu" :key="i">
          <a :href="item.url" @click="showMenu=false" class="turn">{{
              item.title
            }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {login} from "@/api";
import {isMobileDevice} from "@/js";

export default {
  name: "web-header",
  data() {
    return {
      menu: [
        {title: "主页", url: "#/home"},
        {title: "电商IP", url: "#/ISP"},
        {title: "教程与介绍", url: "https://l6cc3twea9.feishu.cn/docx/QrSWdtJrWoZEa2xzm88cBhBynCb"}
      ],
      isphone: isMobileDevice(),
      showMenu: false,
      headerActive: false
    }
  },

  mounted() {
    let that = this;
    window.addEventListener('scroll', function () {
      var y = window.pageYOffset;
      if (y > 72) {
        that.headerActive = true
      } else {
        that.headerActive = false
      }
    });
  },
  methods: {}
}
</script>

<style scoped lang="less">
.header {
  width: 100%;
  margin: auto;
  color: rgba(18, 18, 18, 1);
  position: fixed;
  top: 0px;
  z-index: 99;
  transition: all 0.5s;
  background: #fff;
}

.showdown {
  box-shadow: 55px 10px 50px rgba(0, 0, 0, 0.06);
}

._menuDiv {
  width: 100%;
  height: 220px;
  background: #fcfcfc;
  transition: all 0.5s;


  .menuDiv {
    width: 200px;
    margin: auto;
    height: 40px;
    line-height: 40px;
    text-align: center;

    a {
      text-decoration: none;
      color: rgba(0, 0, 0, 0.46);
    }
  }
}

.login_button {
  text-decoration: none;
  border-radius: 5px;
  padding: 10px 22px;
  position: relative;
  top: 10px;
}

.active {
  background-color: #ff8f1f;
  color: white;
}

.headerActive {
  background-color: #fcfcfc;
  box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
}

.phone {
  display: none;
}

.bg {
  height: auto !important;
  margin: auto;
  position: relative;
}

.login {
  position: relative;
  top: 15px;
  width: 80%;
  float: right;
  text-align: right;
}

.logo {
  min-width: 80px;
  height: 50px;
  line-height: 80px;
  font-size: 24px;
  font-weight: 600;
  margin-top: 14px;


  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.turn {
  color: #00000099;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
}


.turn:hover {
  color: #0f2420;
  transition: all 0.1s;
}

.menu {
  height: 70px;
  width: 420px;
  margin: auto;
}

._menu {
  margin-top: 17px;
}

.menu ul {
  list-style: none;
  width: 420px;
  height: 70px;

  li {
    float: left;
    margin: 10px 20px;
    font-size: 12px;
  }

  a {
    text-decoration: none;
  }
}

.content {
  height: 70px;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 992px) {
  .PC {
    display: none;
  }

  .content {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .phone {
    display: block;
  }

  .header {
    background-color: #fcfcfc;
    box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
  }
}

</style>
