<template>
  <div class="bg" id="pricing">
    <div class="content fadeIn">
      <div class="image">
        <img src="./../../src/assets/tikeAssets/download.png" alt="" style="width: 100%">
      </div>
      <div class="downIcon">
        <div class="title">
          多平台官方插件提升效率
        </div>
        <div class="Icon">
          <div class="_icon">
            <div class="iconImage">
              <img src="./../../src/assets/tikeAssets/ziliao.png" alt="" style="width: 140px">
            </div>
            <div class="iconTitle" style="text-align: center">
              <a href="https://appstore.ziniao.com/plugin/detail/16312716770689/TiKe-TikTok-DaRenZiDongHuaYaoYueShenQi.html"
                 target="_blank">紫鸟插件</a>
            </div>
          </div>
          <div class="_icon">
            <div class="iconImage" style="margin-top: 15px">
              <img src="./../../src/assets/tikeAssets/guge.png" alt="">
            </div>
            <div class="iconTitle" style="margin-top: 15px;text-align: center">
              <a href="https://chromewebstore.google.com/detail/%E6%8F%90%E5%AE%A2-tiktok%E8%BE%BE%E4%BA%BA%E9%82%80%E7%BA%A6%E5%B7%A5%E5%85%B7/pfbalhmhnmaebmjcdokpdjkobkgejdeh"
                 target="_blank">Chrome插件</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {submitUserEmail} from "@/api/index"
import {ElMessage} from 'element-plus'

export default {
  name: "Preimum Price",
  data() {
    return {}
  },

  methods: {},
}
</script>

<style scoped lang="less">
.bg {
  height: auto;
}

.content {
  display: flex;
}

.image {
  flex: 2;
}

.downIcon {
  flex: 3;
}

.downIcon {
  background: #ff8f1f21;
  border-top-left-radius: 20px;
}

.Icon {
  display: flex;
}

._icon {
  flex: 1;
}


.iconImage img {
  width: 100px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.iconTitle a {
  background: #ff8f1f;
  color: #ffffff;
  text-decoration: none;
  padding: 10px 30px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 20px;
  position: relative;
  top: 20px;

}

.title {
  text-align: center;
  color: #ff8f1f;
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  margin-top: 100px;
  margin-bottom: 50px;
}

@media (max-width: 992px) {
  .content {
    display: inline-block;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  .downIcon {
    border-top-right-radius: 20px;
    height: 400px;
  }

  .title {
    line-height: 100px;
    font-size: 24px;
  }

  .iconTitle a {
    padding: 10px 25px;
    font-size: 14px;
  }
}
</style>
